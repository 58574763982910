import type { ParentComponent } from "solid-js";

import { createContext, useContext } from "solid-js";
import { createStore } from "solid-js/store";

export type MapContextType = [
  { values: { nid?: number } },
  {
    setMarkerNid?: (nid: number) => void;
    unsetMarkerNid?: () => void;
  },
];

const MapContext = createContext<MapContextType>([{ values: {} }, {}]);

export const MapContextProvider: ParentComponent<{
  values?: { nid: number | undefined };
}> = (props) => {
  const [state, setState] = createStore({
      // eslint-disable-next-line solid/reactivity
      values: props.values || { nid: undefined },
    }),
    store: MapContextType = [
      state,
      {
        setMarkerNid(nid: number) {
          setState("values", "nid", nid);
        },

        unsetMarkerNid() {
          setState("values", "nid", undefined);
        },
      },
    ];

  return (
    <MapContext.Provider value={store}>{props.children}</MapContext.Provider>
  );
};

export function useMapContext() {
  return useContext(MapContext);
}
