import type { ParentComponent } from "solid-js";
import { createContext, useContext } from "solid-js";
import { createStore } from "solid-js/store";
import type { Lot } from "~/types/drupal_jsonapi";
import type { ProgramWrapper } from "~/utils/program_wrapper";

export type LotActiveContext = [
  { wrapper: ProgramWrapper | undefined; lot: Lot | undefined },
  {
    setLot?: (wrapper: ProgramWrapper, lot: Lot) => void;
    unsetLot?: () => void;
  },
];

const LotActiveContext = createContext<LotActiveContext>([
  { wrapper: undefined, lot: undefined },
  {},
]);

export const LotActiveProvider: ParentComponent<{
  wrapper?: ProgramWrapper;
  lot?: Lot;
}> = (props) => {
  const [state, setState] = createStore({
      // eslint-disable-next-line solid/reactivity
      wrapper: props.wrapper || undefined,
      // eslint-disable-next-line solid/reactivity
      lot: props.lot || undefined,
    }),
    store: LotActiveContext = [
      state,
      {
        setLot(wrapper: ProgramWrapper, lot: Lot) {
          setState("wrapper", wrapper);
          setState("lot", lot);
        },
        unsetLot() {
          setState("lot", undefined);
        },
      },
    ];

  return (
    <LotActiveContext.Provider value={store}>
      {props.children}
    </LotActiveContext.Provider>
  );
};

export function useLotActive() {
  return useContext(LotActiveContext);
}
